.text-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 652px;
}

.vertical{
    border-left: 2px solid #C61980;
    height: 200px;
    margin-right: 20px;
}

.textp-style{
    font-family: 'Satoshi', sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
}

@media screen and (max-width:750px) {

    .text-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .textp-style {
        font-family: 'Satoshi', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
    }

    .vertical {
        border-left: 2px solid #C61980;
        height: 78px;
    }
}
