.contacto-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.titulo-contacto {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 50px;
}

.titulo-contacto h1 {
    font-size: 46px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-right: 20px;
    margin-left: 20px;
}

.cont-contacto{
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    margin-bottom: 150px;

}

.icono {
    width: 36px;
    height: 36px;
    margin-right: 8px;
}

.info-section h3 {
    font-size: 36px;
    margin-bottom: 10px;
    font-weight: 800;
    color: #C61980;
    font-family: 'DM Sans', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.info-section strong{
    font-weight: 500;
}

.contacto-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    flex: 1;
}

.contacto-info {
    flex: 1;
    text-align: left;
    margin-right: 10px;
    margin-top: -45px;
}


.info-section {
    margin-bottom: 20px;
}

.info-section p {
    font-size: 26px;
    margin: 0;
    font-family: 'Satoshi', sans-serif;
}

.redes-container{
    display: block;
    margin-top: 20px;
}

.redes-cont{
    margin-bottom: -50px;
    display: flex;
}

.redes-cont h3{
    font-size: 26px;
    font-weight: 400;
    font-family: 'Satoshi', sans-serif;
}

.redes-cont img{
    width: 36px;
    margin-right: 12px;
}

.space-hor{
    width: 25px;
}

.contacto-map {
    display: block;
    margin-left: 150px;

}

.contacto-map iframe {
    flex: 1;
    width: 600px;
    height: 335px;

}

.imagen-clinica{
    flex: 1;
}

.imagen-clinica img{
    width:600px ;
}

@media screen and (max-width: 1525px) {
    .info-section p {
        font-size: 24px;
    }

    .contacto-map iframe {
        width: 500px;
    }
    
    .imagen-clinica img{
        width:500px ;
    }
}

@media screen and (max-width: 1385px) {

    .titulo-contacto {
        margin-left: 100px;
        margin-right: 100px;
    }
    
    .cont-contacto{
        margin-left: 100px;
        margin-right: 100px;
    }

    .contacto-map {
        margin-left: 100px;
    
    }
}


@media screen and (max-width: 1250px) {
    .info-section p {
        font-size: 20px;
    }

    .info-section h3 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .titulo-contacto h1 {
        font-size: 38px;
    }

    .contacto-map iframe {
        width: 450px;
    }
    
    .imagen-clinica img{
        width:450px ;
    }
    
    .contacto-info {
        margin-top: -20px;
    }

    .icono {
        width: 28px;
        height: 28px;
        margin-right: 8px;
    }

}

@media screen and (max-width: 1050px) {
    .titulo-contacto {
        margin-left: 50px;
        margin-right: 50px;
    }
    
    .cont-contacto{
        margin-left: 50px;
        margin-right: 50px;
    }

    .contacto-map {
        margin-left: 50px;
    }

}


@media (max-width: 850px) {
    .cont-contacto{
        display: block;
        margin: 15px;
    }

    .info-section p {
        font-size: 18px;
    }

    .info-section h3 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .info-section img {
        width: 28px;
        margin-bottom: 2px;
    }


    .titulo-contacto h1 {
        font-size: 38px;
    }

    .contacto-map{
        margin: 10px;
        margin-top: 100px;
    }

    .contacto-map iframe {
        width: 100%;
        height: 225px;
    }
    
    .imagen-clinica img{
        width:100% ;
    }

    .contacto-info {
        margin: 15px;
    }

    .titulo-contacto {
        margin-bottom: 50px;
        margin-left: 25px;
        margin-right: 25px;
        margin-top: 0px;
    }

    .titulo-contacto h1 {
        font-size: 26px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .redes-cont h3{
        font-size: 18px;
    }

    .redes-cont{
        margin-top: 20px;
    }
    
    .redes-cont img{
        width: 28px;
        margin-right: 12px;
    }
}

