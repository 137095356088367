.container-team {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 75px;
    margin-left: 75px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease, transform 1s ease;
}

.container-team.visible {
    opacity: 1;
    transform: translateY(0);
}

.equipo-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 75px;
    margin-bottom: 75px;
    position: relative;
}

.img-personal {
    width: 175px;
    margin-right: 5px;
    margin-left: 5px;
    transition: transform 0.5s ease, width 0.5s ease, opacity 0.5s ease;
}

.img-personal.active {
    width: 325px;
    transform: translateY(-13px) scale(1);
    z-index: 1;
}

.img-personal.active img {
    height: 525px;
}

.img-personal:not(.active) {
    transform: scale(0.9);
    opacity: 0.7;
}

.img-personal img {
    height: 275px;
    width: 100%;
    display: block;
    object-fit: cover;
    object-position: top;
}

.text-overlay {
    position: absolute;
    top: -5%;
    left: 105%;
    width: 100%;
    text-align: left;
    color: Black;
    padding: 10px;
    border-radius: 3px;
    font-size: 24px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name-prof {
    display: flex;
    align-items: baseline;
}

.text-overlay h1 {
    color: #C61980;
    font-weight: 600;
    margin-right: 8px;
    line-height: 1.2;
}

.text-overlay h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
}

.text-overlay p {
    font-weight: 300;
    font-size: 16px;
    margin: 5px 0 0 0;
    max-width: 305px;
    text-overflow: ellipsis;
    white-space: normal;
    margin-top: -15px;
}

.arrow-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 50px;
}

.left-arrow {
    left: -75px;
}

.right-arrow {
    right: -75px;
}

.right-arrow img,
.left-arrow img {
    width: 70px;
}

@media screen and (max-width: 750px) {
    .equipo-container {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 50px;
        margin-bottom: 150px;
    }

    .right-arrow img,
    .left-arrow img {
        width: 50px;
    }

    .img-personal {
        width: 75px;
        height: 50px;
        margin-right: 2px;
        margin-left: 2px;
    }

    .img-personal img{
        height: 100px;
        margin-right: 150px;
    }

    .left-arrow {
        left: -110px;
    }
    
    .right-arrow {
        right: -90px;
    }

    .img-personal.active {
        width: 120px;
        transform: translateY(-107px) scale(1);
        z-index: 1;
        margin-left: 2px;
        margin-top: 50px;
    }

    .img-personal.active img{
        height: 200px;
    }

    .arrow-button {
        top: 60%;
        transform: translateY(-190%);
        z-index: 20;
    }
    .text-overlay {
        top: -45%;
        left: 100%;
        width: 175px;
    }

    .text-overlay h2 {
        font-size: 16px;
        margin-left: -5px;
    }

    .text-overlay h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .text-overlay p {
        font-size: 10px;
    }
}