.especialistas-container {
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    max-width: calc(100% - 300px);
}

.titulo-especialistas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 20px;
}

.titulo-especialistas h1 {
    font-size: 46px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: black;
    margin-right: 20px;
    margin-left: 20px;
}

.contenedor-intros {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
}

.intro-especialistas {
    flex: 1;
    font-family: 'Satoshi', sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: 28px;
    text-align: left;
}

.vertical-space {
    width: 50px;
}

.horizontal-titulo {
    flex-grow: 1;
    height: 2px;
    background-color: #C61980;
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
    box-sizing: border-box;
    grid-auto-rows: auto;
}

.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.grid-item img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    object-position: top;
    transition: opacity 0.3s ease-in-out, filter 0.3s ease-in-out;
}


.grid-item:hover img {
    opacity: 0.2;
    filter: blur(3px);
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 20px;
    font-weight: 400;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    text-align: center;
    padding: 0 25px;
    line-height: 22px;
    box-sizing: border-box;
}


.grid-item:hover .overlay-text {
    opacity: 1;
}

.info {
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.nombre {
    color: #C61980;
    font-size: 24px;
    font-weight: bold;
}

.especialidad {
    font-size: 20px;
    color: black;
}


@media (max-width: 750px) {
    .especialistas-container {
        text-align: center;
        margin: 0 auto;
        max-width: calc(100% - 30px);
    }

    .titulo-especialistas {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0px;
    }

    .titulo-especialistas h1 {
        font-size: 26px;
        margin: 0 5px;
    }

    .contenedor-intros {
        display: block;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .intro-especialistas {
        flex: 1;
        font-family: 'Satoshi', sans-serif;
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
    }

    .intro2{
        display: none;
    }

    .vertical-space {
        width: 15px;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 0px 10px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .grid-item img {
        width: 100%;
        height: 280px;
        object-fit: cover;
    }

    .overlay-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -90%);
        font-size: 12px;
        padding: 0 10px;
        line-height: 14px;
    }

    .nombre {
        color: #C61980;
        font-size: 16px;
        font-weight: bold;
    }
    
    .especialidad {
        font-size: 14px;
        color: black;
    }
}