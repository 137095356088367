.servicio-container {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    height: 425px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
}

.servicio-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(211, 207, 207, 0.6);
    z-index: 1;
    pointer-events: none;
    transition: background 0.3s ease-in-out;
    backdrop-filter: blur(1px);
}

.servicio-container:hover::before {
    background: rgba(128, 128, 128, 0);
    backdrop-filter: none;
}

.servicio-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: inherit;
    background-repeat: inherit;
    background-position: inherit;
    z-index: 0;
    transition: transform 0.3s ease-in-out;
}

.servicio-container:hover::after {
    transform: scale(1.1);
}

.serv-text-2 {
    font-family: 'Satoshi', sans-serif;
    font-size: 46px;
    z-index: 2;
    position: relative;
    color: black;
    font-weight: 400;
    transition: opacity 0.3s ease-in-out;
}

.servicio-container:hover .serv-text-2 {
    opacity: 0;
}

.servicio-button {
    z-index: 2;
    position: absolute;
    bottom: 30px;
    padding: 12px 20px;
    font-size: 26px;
    border: none;
    background: rgba(255, 255, 255, 0.8);
    color: #C61980;
    cursor: pointer;
    border-radius: 10px;
    opacity: 0;
    font-family: 'Satoshi' sans-serif;
    font-weight: 500;
    transition: opacity 0.3s ease-in-out;
}

.servicio-container:hover .servicio-button {
    opacity: 1;
}

.especialidad{
    margin-bottom: 75px;
}

@media screen and (max-width: 750px) {
    .titulo-servicios {
        margin-bottom: 50px;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 25px;
    }

    .serv-text-2 {
        font-family: 'Satoshi', sans-serif;
        font-size: 18px;
    }

    .servicios-contenedor {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;

    }

    .servicio-container{
        height: 165px;
    }

    .servicio-button {
        font-size: 16px;
        padding: 8px 14px;
    }

}