body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #C61980 transparent;
}

.titulo-contacto {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
}

.titulo-servicios h1{
    font-size: 46px;
    font-family: 'DM Sans' sans-serif;
}

.horizontal-titulo {
    flex-grow: 1;
    height: 2px;
    background-color: #C61980;
}

.scrollToTopBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgb(198,25,128);
    border: none;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
}

.scrollToTopBtn:hover {
    background-color: rgb(94, 12, 61);
}

.scrollToTopBtn .icon {
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 750px) {
    .scrollToTopBtn {
        width: 40px;
        height: 40px;
    }
    
    .scrollToTopBtn .icon {
        width: 20px;
        height: 20px;
    }
    
}
