@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn 0.35s ease-in-out;
}

.fade-out {
    animation: fadeOut 0.35s ease-in-out;
}

.carousel-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    margin-top: -25px;
    width: 100%;
}

.carousel-inner-2 {
    width: 600px;
    height: 275px;
    padding: 20px;
    background-color: white;
    position: relative;
    overflow: hidden;
    transition: opacity 0.35s ease-in-out;
    opacity: 1;
}

button.carousel-control-2 {
    position: absolute;
    top: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 25px;
    z-index: 1000;
    transform: translateY(-50%);
}

button.carousel-control-prev-2 {
    left: -50px;
}

button.carousel-control-next-2 {
    right: -50px;
}

button.carousel-control-2 img {
    width: 50px;
    height: 50px;
}

.carousel-inner-2.hidden {
    visibility: hidden;
}
