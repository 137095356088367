.dropdown-menu {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.99);
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 20;
    justify-content: center;
    align-items: center;
}

.option {
    text-decoration: none;
    color: black;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    position: relative;
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    display: inline-block;
    margin-top: 10px;
    font-size: 16px;
    padding: 10px;
}

.option:last-child {
    margin-bottom: 15px;
}

.option:first-child {
    margin-top: 15px;

}

.option:hover {
    color: #C61980;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.option.active {
    color: #C61980;
    font-weight: 600;
    margin-bottom: 10px;
}

.option.active::after {
    content: '';
    display: block;
    width: 120%;
    height: 2px;
    background-color: #C61980;
    position: absolute;
    bottom: 5px;
    left: -10%;
}

@media screen and (max-width: 750px) {
    .menu-icon-button {
        display: block;
        background: transparent;
        border: none;
        position: absolute;
        right: 0px;
        top: 20px;
    }

    .menu-icon-button img {
        height: 30px;
    }
}