.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #cfcece;
    color: white;
    padding-right: 150px;
    padding-left: 150px;
}

.footer-logo {
    flex: 1;
}

.footer-logo-image {
    margin-top: 0px;
    max-height: 20px;
}

.footer-links {
    margin-top: 0px;
    flex: 2;
    display: flex;
    justify-content: center;
}

.footer-link {
    margin-top: 0px;
    margin-left: 20px;
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.footer-link:hover {
    text-decoration: underline;
    color: #C61980;
}

.footer-copy {
    margin-top: 0px;
    color: #C61980;
    flex: 1;
    text-align: right;
}

.footer-copy p {
    margin: 0;
    font-size: 14px;
}

@media (max-width: 750px) {
    .footer {
        display: block;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: #cfcece;
        color: white;
        padding-right: 5px;
        padding-left: 5px;
    }
    
    .footer-logo {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    
    .footer-logo-image {
        display: none;
        margin-top: 5px;
        max-height: 12px;
        margin-bottom: 10px;
        align-items: center;
    }

    .footer-links {
        margin-top: 0px;
        flex: 2;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    
    .footer-link {
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: 10px;
    }

    .footer-link:first-child {
        margin-left: 0px;
    }
    
    .footer-link:hover {
        text-decoration: underline;
        color: #C61980;
    }
    
    .footer-copy {
        color: #C61980;
        flex: 1;
        margin-top: 3px;
        text-align: center;
    }
    
    .footer-copy p {
        margin: 0;
        font-size: 10px;
    }
}
