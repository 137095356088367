.nosotros-container {
  text-align: center;
  margin: 0 150px;
}

.titulo-nosotros {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.horizontal-titulo {
  flex-grow: 1;
  height: 2px;
  background-color: #C61980;
  margin: 0;
}

.titulo-nosotros h1 {
    font-size: 46px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-right: 20px;
    margin-left: 20px;
}

.images-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 150px;
}

.images-container img {
  width: 33.33%;
  height: 650px;
  margin: 0;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
}

.images-container img.visible {
  opacity: 1;
  transform: translateY(0);
}

.text-image-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
}

.text-image-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.text-image-section img {
  width: 40%;
  height: 650px;
  margin-bottom: 150px;
}

.text-content {
  width: 45%;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 150px;
}

.text-content p {
  font-family: 'Satoshi', sans-serif;
  font-size: 26px;
  font-weight: 300;
  line-height: 28px;
}

.hor-text{
  margin-top: 20px;
  width: 150px;
  height: 2px;
  background-color: #C61980;
  margin-left: auto;
  margin-right: auto;
}

.hor-text2{
  margin-top: 20px;
  width: 150px;
  height: 2px;
  background-color: #C61980;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.promo-section {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 950px;
  overflow: hidden;
}

.promo-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.25;
  display: block;
}

.promo-text {
  position: absolute;
  text-align: center;
  color: #C61980;
  padding: 10px 20px;
  max-width: 80%;
}

.promo-text h2 {
  font-size: 50px;
  font-family: 'DM Sans', sans-serif;
  margin: 0;
  font-weight: 400;
  line-height: 52px;
}


@media screen and (max-width: 1525px) {

}


@media screen and (max-width: 1250px) {


}

@media screen and (max-width: 1050px) {


}

@media screen and (max-width: 850px) {

}

@media (max-width: 750px) {

  .nosotros-container {
    margin: 0 10px;
  }

  .titulo-nosotros {
      margin-bottom: 50px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 25px;
      margin-top: 0px;
  }

  .titulo-nosotros h1 {
      font-size: 26px;
      margin-right: 5px;
      margin-left: 5px;
  }

  .images-container{
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .images-container img {
    width: 33.33%;
    height: 165px;
    transform: translateY(50px);
  }

  .text-image-section img {
    width: 40%;
    height: 165px;
    margin-bottom: 50px;
  }
  
  .text-content {
    width: 100%;
    text-align: left;
    margin-bottom: 25px;
  }

  .hor-text{
    display: none;
  }

  .hor-text2{
    margin-top: -20px;
    width: 350px;
    height: 2px;
    display: block;
  }
  
  .text-content p {
    font-size: 18px;
    line-height: 20px;
    margin-right: 15px;
    margin-left: 15px;
  }

  .text-image-section {
    display: block;
  }

  .promo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.25;
    display: block;
  }
  
  .promo-text {
    padding: 10px 20px;
    max-width: 80%;
  }
  
  .promo-text h2 {
    font-size: 24px;
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    font-weight: 400;
    line-height: 26px;
  }

  .promo-section {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 375px;
    overflow: hidden;
    object-fit: contain;
  }
  
}

