.exp-container{
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    width: 425px;
    height: 425px;
}

.mas-text{
    display: flex;
}

.exp-style{
    font-size: 48px;
    font-family: 'DM Sans' sans-serif;
    font-weight: 200;
}

.mas-text h2 {
    margin-top: -40px;
    margin-bottom: 0px;
    font-size: 150px;
    font-family: 'DM Sans' sans-serif;
    font-weight: 500;
    color: #C61980;
}


@media screen and (max-width:750px) {
    .exp-container {
        height: auto;
        width: auto;
        align-items: center;
        text-align: center;
    }

    .mas-text h2 {
        margin-top: 0px;
        font-size: 34px;
        text-align: center;
    }

    .exp-style {
        font-size: 16px;
        font-family: 'DM Sans', sans-serif;
        font-weight: 200;
        text-align: center;
    }
}