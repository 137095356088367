.head-cookies {
    padding-bottom: 110px;
}

.text-cookies {
    margin-left: 150px;
    margin-right: 150px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    margin-bottom: 50px;
}

.text-cookies h1 {
    color: #C61980;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.text-cookies h2 {
    color: #333;
    font-size: 1.8em;
    margin-top: 40px;
    margin-bottom: 10px;
}

.text-cookies p {
    margin-bottom: 20px;
}

.text-cookies ul {
    margin-left: 20px;
    margin-bottom: 20px;
}

.text-priv ul li {
    margin-bottom: 10px;
}

.text-cookies a {
    color: #C61980;
    text-decoration: none;
}

.text-cookies a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .text-cookies {
        margin-left: 25px;
        margin-right: 25px;
        margin-top: -25px;
    }

    .text-cookies h1 {
        font-size: 1.8em;
    }
    
}