.titulo-servicios {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 150px;
    margin-right: 150px;
}

.horizontal-titulo {
    flex-grow: 1;
    height: 2px;
    background-color: #C61980;
}

.titulo-servicios h1 {
    font-size: 46px;
    font-family: 'Satoshi', sans-serif;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-right: 20px;
    margin-left: 20px;
}

.servicio-text-container {
    display: flex;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.service-active {
    opacity: 1;
    background-color: #f0f0f0;
    width: 100%;
}


.servicio-text {
    flex: 1;
    padding: 20px;
    transition: background-color 0.3s ease;
}

.service-active {
    background-color: #f0f0f0;
    width: 100%;
}

@media screen and (max-width: 550px) {

    .cont-serv{
        margin-top: -50px;

    }

    .titulo-servicios {
        margin-top: -50px;
        margin-bottom: 50px;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 25px;
    }

    .titulo-servicios h1 {
        font-size: 26px;
        margin-right: 5px;
        margin-left: 5px;
    }
}