.text-service-cont {
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.titulo-servicios {
    margin-bottom: 100px;
}

.content-serv {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.space-inferior{
    margin: 75px;
}

.text-title{
    align-items: center;
    align-content: center;
}

.titulo-serv-text {
    margin-right: 20px;
    margin-bottom: 20px;
}

.titulo-serv-text h2 {
    color: #C61980;
    font-size: 36px;
    margin-bottom: -30px;
}

.parrafos-serv {
    display: flex;
    flex-direction: column;
    flex: 3;
    max-width: 1250px;
}

.parrafo-serv p {
    font-size: 24px;
    line-height: 26px;
    text-align: start;
    font-weight: 300;
}

.img-serv {
    flex: 2;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 20px;
}

.img-serv img {
    width: 100%;
    height: 400px;
    width: 500px;
    object-fit: cover;
    margin: 0;
    margin-left: 150px;
    background-color: #f0f0f0;
    box-sizing: border-box;
}

@media screen and (max-width: 750px) {
    
    .titulo-servicios {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .text-service-cont {
        margin-left: 25px;
        margin-right: 25px;
    }

    .img-serv {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }

    .img-serv img {
        width: 250px;
        height: 200px;
        margin: 0;
        object-fit: cover;
    }

    .content-serv {
        display: block;
        width: 100%;
    }

    .parrafo-serv {
        margin-bottom: -20px;
    }
    

    .parrafo-serv p {
        font-size: 18px;
        line-height: 20px;
        text-align: start;
        font-weight: 300;
    }

    .titulo-serv-text h2 {
        color: #C61980;
        font-size: 28px;
        margin-bottom: -30px;
    }
}