.imageContainer {
    position: relative;
    text-align: left;
    color: white;
}

.menu-icon-button {
    display: none;
}

.valoracion-mov {
    display: none;
}

.header {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 150px;
    backdrop-filter: blur(5px);
    z-index: 10;
}

.horizontal-3{
    display: none;
}

.header img {
    width: 200px;
}

.options {
    display: flex;
}

.menu-options {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-options li {
    margin-left: 80px;
}

.menu-options a {
    color: black;
    font-size: 20px;
    margin-top: 10px;
    text-decoration: none;
    position: relative;
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    display: inline-block;
}

.first-visit {
    margin-top: -45px;
    text-align: left;
    font-size: 36px;
    font-weight: 200;
    color: black;
    opacity: 0;
    display: block;
    transform: translateY(-20px);
    animation: fadeInAndSlideUp 1s ease-in-out forwards;
    animation-delay: 3s;
}

.short-opinion{
    display: none;
}

@keyframes fadeInAndSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


.first-visit p {
    font-size: 36px;
    font-weight: 200;
    color: black;
}

.phone-cont {
    display: flex;
    align-items: center;
    margin-right: 40px;
    z-index: 20;
}


.phone-icon-circle {
    width: 40px;
    height: 40px;
    background-color: #C61980;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    margin-bottom: 15px;
    margin-right: 10px;
    transform: translateY(-15px);
}

.phone-number {
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 24px;
    color: black;
    font-weight: 500;
    font-family: 'Satoshi', sans-serif;
    z-index: 20;
    margin-bottom: 25px;
    margin-right: 20px;
}

.phone-cont:hover .phone-number {
    opacity: 1;
    transform: translateX(0);
    transform: translateY(-10px);
}

.phone-icon-circle img {
    width: 20px;
    height: 20px;
}

.menu-options a:hover {
    color: #C61980;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.menu-options .active {
    color: #C61980;
    font-weight: 600;
}

.menu-options .active::after {
    content: '';
    display: block;
    width: 120%;
    height: 2px;
    background-color: #C61980;
    position: absolute;
    bottom: -5px;
    left: -10%;
}

.imagenClinica {
    width: 100%;
    height: auto;
    opacity: 0.25;
    position: relative;
    z-index: 1;
}

.rosaBarra {
    background: linear-gradient(to bottom, rgba(198, 25, 128, 1), rgba(243, 147, 205, 0.7), rgba(239, 186, 218, 0.4), rgb(255, 255, 255, 0));
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    filter: blur(2px);
    z-index: -5;
}

.texto-imagen {
    position: absolute;
    top: 250px;
    left: 150px;
    text-align: left;
    z-index: 10;
}

.not-bold-text, .bold-text {
    font-size: 70px;
    font-family: 'DM Sans', sans-serif;
    color: black;
    display: block;
}

.not-bold-text {
    font-weight: 200;
}

.bold-text {
    font-weight: bold;
    color: #C61980;
    margin-left: 20px;
}

.first-line, .second-line {
    display: flex;
}

.second-line {
    margin-top: -120px;
    visibility: hidden;
}

.first-line {
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    animation: showFirstLine 0s linear 2s forwards, typing 1.5s steps(40, end) 2s forwards;
}

.second-line {
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    animation: showSecondLine 0s linear 3.5s forwards, typingSecond 1.5s steps(30, end) 3.5s forwards;
}

@keyframes typing {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes showFirstLine {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes showSecondLine {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes typingSecond {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.equipo-cont {
    margin-top: -50px;
}

.titulo-val {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative;
}

.titulo-equipo {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative;
}

.horizontal-2 {
    flex: 1;
    border-bottom: 0.01rem solid #C61980;
    margin: 0 15px;
}

.valoraciones-cont, .equipo-cont, .mapa-cont {
    margin-left: 100px;
    margin-right: 100px;
}

.mapa-cont {
    margin-bottom: 75px;
}

.expertise {
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    margin-top: -50px;
}

.expertise > div {
    flex: 0 0 calc(33.33% - 30px);
    margin: 15px;
}

.servicios-contenedor {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 50px;
}

.servicios-contenedor > div {
    flex: 0 0 calc(33.33% - 30px);
    margin: 15px;
}

.textos-contenedor {
    display: flex;
    justify-content: space-between;
    margin-left: 165px;
    margin-right: 165px;
    margin-top: 50px;
}

.textos-contenedor > div {
    flex: 0 0 calc(50% - 30px);
}

@media screen and (max-width: 1500px) {
    .header {
        padding: 30px 100px;
    }

    .header img {
        width: 180px;
    }

    .menu-options li {
        margin-left: 60px;
    }

    .menu-options a {
        font-size: 18px;
        margin-top: 10px;
    }

    .texto-imagen {
        top: 175px;
        left: 100px;
    }

    .not-bold-text, .bold-text {
        font-size: 58px;
    }

    .bold-text {
        margin-left: 18px;
    }

    .second-line {
        margin-top: -110px;
    }

    .textos-contenedor {
        margin-left: 115px;
        margin-right: 115px;
    }

    .first-visit{
        margin-top: -55px;
    }

    .expertise {
        margin-left: 100px;
        margin-right: 100px;
    }

    .servicios-contenedor {
        margin-left: 100px;
        margin-right: 100px;
    }

}

@media screen and (max-width: 1280px) {
    .header {
        padding: 30px 80px;
    }

    .menu-options li {
        margin-left: 40px;
    }

    .header img {
        width: 160px;
    }

    .first-visit{
        margin-top: -70px;
    }

    .menu-options a {
        font-size: 16px;
        margin-top: 10px;
    }

    .texto-imagen {
        top: 140px;
        left: 80px;
    }

    .not-bold-text, .bold-text {
        font-size: 50px;
    }

    .bold-text {
        margin-left: 16px;
    }

    .second-line {
        margin-top: -100px;
    }

    .textos-contenedor {
        margin-left: 95px;
        margin-right: 95px;
    }

    .expertise {
        margin-left: 80px;
        margin-right: 80px;
    }

    .servicios-contenedor {
        margin-left: 80px;
        margin-right: 80px;
    }
}

@media screen and (max-width: 1050px) {
    .header {
        padding: 30px 60px;
    }

    .menu-options li {
        margin-left: 30px;
    }

    .header img {
        width: 140px;
    }

    .menu-options a {
        font-size: 14px;
        margin-top: 8px;
    }

    .texto-imagen {
        top: 160px;
        left: 60px;
    }

    .not-bold-text, .bold-text {
        font-size: 40px;
    }

    .bold-text {
        margin-left: 14px;
    }

    .second-line {
        margin-top: -90px;
    }

    .textos-contenedor {
        margin-left: 75px;
        margin-right: 75px;
    }

    .expertise {
        margin-left: 60px;
        margin-right: 60px;
    }

    .servicios-contenedor {
        margin-left: 60px;
        margin-right: 60px;
    }
}

@media screen and (max-width: 860px) {
    .header {
        padding: 30px 40px;
    }

    .header img {
        width: 130px;
    }

    .menu-options li {
        margin-left: 20px;
    }

    .menu-options a {
        font-size: 14px;
        margin-top: 8px;
    }

    .texto-imagen {
        top: 140px;
        left: 40px;
    }

    .not-bold-text, .bold-text {
        font-size: 36px;
    }

    .bold-text {
        margin-left: 10px;
    }

    .second-line {
        margin-top: -80px;
    }

    .textos-contenedor {
        margin-left: 55px;
        margin-right: 55px;
    }

    .expertise {
        margin-left: 40px;
        margin-right: 40px;
    }

    .servicios-contenedor {
        margin-left: 40px;
        margin-right: 40px;
    }

    .phone-cont {
        margin-right: 80px;
    }

    .phone-icon-circle {
        width: 50px;
        height: 50px;
    }

    .phone-icon-circle img {
        width: 25px;
        height: 25px;
    }

    .phone-number {
        font-size: 20px;
    }
}

@media screen and (max-width: 750px) {
    .header {
        padding: 20px 30px;
    }

    .header img {
        width: 120px;
    }

    .menu-options {
        display: none;
    }

    .menu-icon-button {
        display: block;
        background: transparent;
        border: none;
        margin-left: 165px;
    }

    .menu-icon-button img {
        height: 30px;
    }

    .texto-imagen {
        top: 120px;
        left: 20px;
    }

    .not-bold-text, .bold-text {
        font-size: 32px;
    }

    .bold-text {
        margin-left: 8px;
    }

    .second-line {
        margin-top: -70px;
    }

    .textos-contenedor {
        flex-direction: column;
        margin-left: 35px;
        margin-right: 35px;
    }

    .textos-contenedor > div {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }

    .servicios-contenedor {
        flex-direction: column;
        margin-left: 30px;
        margin-right: 30px;
    }

    .servicios-contenedor > div {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }

    .phone-cont {
        margin-right: 60px;
    }

    .phone-icon-circle {
        width: 45px;
        height: 45px;
    }

    .phone-icon-circle img {
        width: 20px;
        height: 20px;
    }

    .phone-number {
        font-size: 18px;
    }
}

@media screen and (max-width: 750px) {
    .header {
        padding: 20px;
    }

    .short-opinion {
        display: block;
        color: black;
        text-align: center;
    }

    .long-opinion{
        display: none;
    }

    .header img {
        width: 100px;
    }

    .imagenClinica{
        margin-top: 35px;
        height: 250px;
        width: 100%;
        object-fit:contain;
    }

    .texto-imagen {
        top: 75px;
        left: 25px;
    }

    .not-bold-text, .bold-text {
        font-size: 24px;
    }

    .bold-text {
        margin-left: 6px;
    }

    .second-line {
        margin-top: -45px;
    }

    .textos-contenedor {
        flex-direction: column;
        margin-top: -10px;
        margin-bottom: -40px;
        margin-left: 25px;
    }

    .textos-contenedor > div {
        flex: 0 0 100%;
        margin: 0;
    }
    .expertise {
        margin-left: 20px;
        margin-right: 20px;
    }

    .first-visit p {
        font-size: 16px;
        font-weight: 200;
        color: black;
        margin-top: 60px;
    }

    .servicios-contenedor {
        margin-left: 10px;
        margin-right: 10px;
    }

    .servicios-contenedor > div {
        flex: 0 0 calc(50% - 20px);
    }

    .phone-cont {
        margin-top: 25px;
        margin-right: 40px;
    }

    .phone-icon-circle {
        width: 20px;
        height: 20px;
    }

    .phone-icon-circle img {
        width: 10px;
        height: 10px;
    }

    .phone-number {
        font-size: 12px;
        margin-left: -5px;
    }

    .rosaBarra {
        height: 2px;
        margin-bottom: 34px;
        display: none;
    }

    .expertise {
        flex-direction: row;
        justify-content: center;
        margin: 0;
        margin-bottom: 50px;
        align-items:end;
    }

    .expertise > div {
        flex: 0 0 calc(33.33% - 25px);
        margin: 5px;
    }

    .titulo-val {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        position: relative;
        font-size: 11px;
    }
    .equipo-cont {
        margin: 20px;
        margin-top: -50px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: -75px;
    
    }

    .op-va-tit {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .tit-eq{
        margin-bottom: 25px;
    }

    .titulo-val h1{
        text-align: center;
    }
    
    .titulo-equipo {
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0px 0;
        position: relative;
    }

    .valoraciones-cont, .equipo-cont, .mapa-cont {
        margin-left: 25px;
        margin-right: 25px;
    }
    
    .mapa-cont iframe {
        height: 350px;
    }
    
    .horizontal-2 {
        flex: 1;
        border-bottom: 2px solid #C61980;
        margin: 0 5px;
    }

    .valoracion-mov {
        display: block;
    }
    .valoraciones {
        display: none;
    }
}