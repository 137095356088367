.imageContainer-2 {
    position: relative;
    text-align: left;
    color: white;
}

.menu-icon-button-2 {
    display: none;
}

.header-2 {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 150px;
    backdrop-filter: blur(20px);
    background: rgba(211, 209, 209, 0.25);
    z-index: 10;
}

.header-2 img {
    width: 200px;
}

.options-2 {
    display: flex;
}

.menu-options-2 {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-options-2 li {
    margin-left: 80px;
}

.menu-options-2 a {
    color: black;
    font-size: 20px;
    margin-top: 10px;
    text-decoration: none;
    position: relative;
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
    display: inline-block;
}

.menu-options-2 a:hover {
    color: #C61980;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.menu-options-2 .active-2 {
    color: #C61980;
    font-weight: 600;
}

.menu-options-2 .active-2::after {
    content: '';
    display: block;
    width: 120%;
    height: 2px;
    background-color: #C61980;
    position: absolute;
    bottom: -5px;
    left: -10%;
}

.imagenClinica-2 {
    width: 100%;
    opacity: 0.25;
    position: relative;
    z-index: 1;
}

.rosaBarra-2 {
    background: linear-gradient(to bottom, rgba(198, 25, 128, 1), rgba(243, 147, 205, 0.7), rgba(239, 186, 218, 0.4), rgb(255, 255, 255, 0));
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    filter: blur(2px);
    z-index: -5;
}

.texto-imagen-2 {
    position: absolute;
    top: 250px;
    left: 150px;
    text-align: left;
    z-index: 10;
}

.not-bold-text-2, .bold-text-2 {
    font-size: 70px;
    font-family: 'DM Sans', sans-serif;
    color: black;
    display: block;
}

.not-bold-text-2 {
    font-weight: 200;
}

.bold-text-2 {
    font-weight: bold;
    color: #C61980;
    margin-left: 20px;
}

.first-line-2, .second-line-2 {
    display: flex;
}

.second-line-2 {
    margin-top: -120px;
    visibility: hidden;
}

.first-line-2 {
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    animation: showFirstLine-2 0s linear 2s forwards, typing-2 1.5s steps(40, end) 2s forwards;
}

@keyframes typing-2 {
    0% { width: 0; }
    100% { width: 100%; }
}

@keyframes showFirstLine-2 {
    0% { visibility: hidden; }
    100% { visibility: visible; }
}

.second-line-2 {
    overflow: hidden;
    white-space: nowrap;
    visibility: hidden;
    animation: showSecondLine-2 0s linear 3.5s forwards, typingSecond-2 1.5s steps(30, end) 3.5s forwards;
}

@keyframes showSecondLine-2 {
    0% { visibility: hidden; }
    100% { visibility: visible; }
}

@keyframes typingSecond-2 {
    0% { width: 0; }
    100% { width: 100%; }
}

.expertise-2 {
    margin-left: 150px;
    margin-right: 150px;
    display: flex;
    margin-top: -50px;
}

.expertise-2 > div {
    flex: 0 0 calc(33.33% - 30px);
    margin: 15px;
}

.servicios-contenedor-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 50px;
}

.servicios-contenedor-2 > div {
    flex: 0 0 calc(33.33% - 30px);
    margin: 15px;
}

.textos-contenedor-2 {
    display: flex;
    justify-content: space-between;
    margin-left: 165px;
    margin-right: 165px;
    margin-top: 50px;
}

.textos-contenedor-2 > div {
    flex: 0 0 calc(50% - 30px);
}

@media screen and (max-width:1500px) {
    
    .header-2 {
        padding: 30px 100px;
    }
    
    .header-2 img {
        width: 180px;
    }

    .menu-options-2 li {
        margin-left: 60px;
    }
    
    .menu-options-2 a {
        font-size: 18px;
        margin-top: 10px;
    }
    
    .texto-imagen-2 {
        top: 200px;
        left: 100px;
    }
    
    .not-bold-text-2, .bold-text-2 {
        font-size: 60px;
    }

    .bold-text-2 {
        margin-left: 18px;
    }
    
    .second-line-2 {
        margin-top: -110px;
    }

    .textos-contenedor-2 {
        margin-left: 115px;
        margin-right: 115px;
    }

    .expertise-2 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .servicios-contenedor-2 {
        margin-left: 100px;
        margin-right: 100px;
    }
    
}

@media screen and (max-width:1225px) {
    
    .header-2 {
        padding: 30px 80px;
    }
    .menu-options-2 li {
        margin-left: 40px;
    }
    .header-2 img {
        width: 160px;
    }
    
    .menu-options-2 a {
        font-size: 16px;
        margin-top: 10px;
    }
    
    .texto-imagen-2 {
        top: 180px;
        left: 80px;
    }
    
    .not-bold-text-2, .bold-text-2 {
        font-size: 50px;
    }

    .bold-text-2 {
        margin-left: 16px;
    }
    
    .second-line-2 {
        margin-top: -100px;
    }

    .textos-contenedor-2 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .expertise-2 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .servicios-contenedor-2 {
        margin-left: 80px;
        margin-right: 80px;
    }

}

@media screen and (max-width:1050px) {
    
    .header-2 {
        padding: 30px 60px;
    }

    .menu-options-2 li {
        margin-left: 0px;
    }

    .header-2 img {
        width: 130px;
    }
    
    .menu-options-2 a {
        font-size: 14px;
        margin-top: 10px;
    }
    
    .texto-imagen-2 {
        top: 160px;
        left: 60px;
    }
    
    .not-bold-text-2, .bold-text-2 {
        font-size: 40px;
    }

    .bold-text-2 {
        margin-left: 16px;
    }
    
    .second-line-2 {
        margin-top: -80px;
    }

    .textos-contenedor-2 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .expertise-2 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .servicios-contenedor-2 {
        margin-left: 60px;
        margin-right: 60px;
    }
    
}

@media screen and (max-width: 850px) {
    .header-2 {
        padding: 30px 60px;
    }

    .menu-icon-button-2 {
        display: block;
        background-color: transparent;
        border: none;
    }

    .menu-icon-button-2 img {
        height: 40px;
    }

    .menu-options-2 {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 65px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.99);
        backdrop-filter: blur(10px);
        z-index: 20;
    }

    .menu-options-2.show-2 {
        display: flex;
    }
    
    .menu-options-2 a{
        padding: 3px;
    }
    .menu-options-2 a:last-child {
        margin-bottom: 15px;
    }
    .menu-options-2 a:first-child {
        margin-top: 15px;
    }

    .header-2 img {
        width: 130px;
    }

    .menu-options-2 a {
        font-size: 18px;
    }

}

@media screen and (max-width:750px) {
    
    .header-2 {
        padding-left: 20px;
        padding-right: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
        justify-content: space-between;
        display: flex;
    }
    
    .header-2 img {
        margin-top: 5px;
        width: 100px;
    }

    .menu-icon-button-2 img {
        height: 30px;
        transform: translateX(10px);
    }

    

    .menu-options-2 a {
        font-size: 16px;
    }

    .header-2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        backdrop-filter: blur(20px);
        background: rgba(211, 209, 209, 0.25);
        z-index: 10;
    }
    
}
