.valoracion-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
}

.valoracion-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
}

.valoracion-user {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.valoracion-user-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: -10px;
}

.valoracion-user-name {
    font-size: 14px;
    color: #555;
}

.valoration-stars {
    justify-content: center;
    display: flex;
}

.valoration-stars img {
    width: 16px;
    transition: fill 0.5s ease;
}

.valoration-stars.filled img {
    filter: brightness(0) saturate(100%) hue-rotate(50deg);
}

.valoration-stars.filled img {
    filter: invert(48%) sepia(99%) saturate(6577%) hue-rotate(8deg) brightness(96%) contrast(101%);
}

@media screen and (max-width: 768px) {
    .valoracion-user-image {
        width: 40px;
        height: 40px;
    }

    .valoracion-user-name {
        font-size: 12px;
    }

    .valoracion-text {
        font-size: 12px;
    }
    
}