.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

}

.arrow-icon {
    width: 70px;
}

.carousel-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 2000px;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-inner > * {
    flex: 0 0 calc(33.33% - 30px);
    box-sizing: border-box;
    margin: 0 15px;
    padding: 0;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0px;
    z-index: 1;
}

.carousel-control.prev {
    left: 0px;
}

.carousel-control.next {
    right: 0px;
}

@media screen and (max-width: 750px) {
    .carousel{
        display: none;
    }
}