.head-aviso{
    padding-bottom: 110px;
}

.text-aviso {
    margin-left: 150px;
    margin-right: 150px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    margin-bottom: 50px;
}

.text-aviso h1 {
    color: #C61980;
    font-size: 2.5em;
    margin-bottom: 20px;
}

.text-aviso h2 {
    color: #333;
    font-size: 1.8em;
    margin-top: 40px;
    margin-bottom: 10px;
}


.text-aviso p {
    margin-bottom: 20px;
}


.text-aviso ul {
    margin-left: 20px;
    margin-bottom: 20px;
}

.text-aviso ul li {
    margin-bottom: 10px;
}


.text-aviso a {
    color: #C61980;
    text-decoration: none;
}


.text-aviso a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .text-aviso {
        margin-left: 25px;
        margin-right: 25px;
        margin-top: -25px;
    }

    .text-aviso h1 {
        font-size: 1.8em;
    }

    .text-aviso h2 {
        font-size: 1.5em;
    }
}
